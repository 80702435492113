<template>
  <div>
    <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader>
    <KTCodePreview v-bind:title="'Danh sách hóa đơn bán lẻ'">
      <template v-slot:toolbar>
        <div class="row">
          <div>
            <b-dropdown size="sm" id="dropdown-1" right>
              <template slot="button-content">
                <i style="font-size: 1rem" class="fas fa-cog"></i>
                <span class="font-weight-bolder">Thao tác</span>
              </template>
              <b-dropdown-item @click="showModalExcel">
                <span>
                  <i style="font-size: 1rem" class="far fa-file-excel"></i>
                  &nbsp; Xuất Excel</span
                >
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <div v-if="checkPermission('BILL_INSERT')" class="ml-3">
            <router-link to="/bills/add-bill">
              <button
                type="button"
                class="btn btn-primary font-weight-bolder btn-sm"
              >
                <i style="font-size: 1rem" class="flaticon2-add-1"></i>Tạo mới
              </button>
            </router-link>
          </div>
        </div>
      </template>
      <template v-slot:preview>
        <b-row class="mb-5">
          <b-col>
            <b-form-select
              size="sm"
              v-model="selectedCompany"
              :options="listCompany"
              value-field="id"
              text-field="name"
              v-on:change="onChangeCompany"
            >
              <template v-slot:first>
                <b-form-select-option :value="null" disabled
                  >-- Doanh nghiệp --</b-form-select-option
                >
              </template>
            </b-form-select>
          </b-col>
          <b-col>
            <multiselect
              v-model="valueStore"
              :options="optionsStore"
              :multiple="true"
              track-by="code"
              :close-on-select="false"
              @select="onSelectStore($event)"
              @remove="onRemoveStore($event)"
              :show-labels="false"
              :showNoResults="false"
              :showPointer="false"
              :custom-label="customLabelStore"
              placeholder="Chọn cửa hàng"
            >
              <template slot="tag">{{ '' }}</template>
              <template slot="selection" slot-scope="{ values, isOpen }"
                ><span
                  class="multiselect__single"
                  v-if="values.length &amp;&amp; !isOpen"
                  >{{ valueStore.length }} cửa hàng đã chọn</span
                ></template
              >
              <template v-slot:no-result> Danh sách cửa hàng rỗng! </template>
              <span
                class="checkbox-label"
                slot="option"
                slot-scope="scope"
                @click.self="select(scope.option)"
              >
                {{ scope.option.name }}
                <input
                  class="test"
                  type="checkbox"
                  v-model="scope.option.checked"
                  @focus.prevent
                />
              </span>
              <span
                class="checkbox-label"
                style="font-size: 12px"
                slot="noResult"
              >
                Không có kết quả
              </span>
            </multiselect>
          </b-col>
          <b-col>
            <b-input
              placeholder="Nhập tên sản phẩm"
              v-model="searchProductName"
              append-icon="search"
              single-line
              hide-details
              class="btn-sm customer-input"
              size="sm"
              v-on:keyup.enter="onFilter()"
            ></b-input>
          </b-col>
          <b-col>
            <div class="search-col">
              <b-input-group>
                <date-picker
                  placeholder="Từ ngày"
                  class="form-control form-control-sm customer-input"
                  :config="dpConfigs.date"
                  v-model="dateFrom"
                ></date-picker>
              </b-input-group>
              <span class="ml-1 mr-1"></span>
              <b-input-group>
                <date-picker
                  placeholder="Tới ngày"
                  class="form-control form-control-sm customer-input"
                  :config="dpConfigs.date"
                  v-model="dateTo"
                ></date-picker>
              </b-input-group>
            </div>
          </b-col>
          <b-col>
            <vue-autosuggest
              class="customer-input"
              v-model="searchEmployee"
              :suggestions="filteredOptionsEmployee"
              @selected="onSelectedEmployee"
              :limit="10"
              @input="onInputChangeEmployee"
              :input-props="{
                id: 'autosuggest__input',
                placeholder: 'Nhân viên bán hàng',
              }"
            >
              <div
                slot-scope="{ suggestion }"
                style="display: flex; align-items: center"
              >
                {{ suggestion.item.fullName }}
              </div></vue-autosuggest
            >
          </b-col>
        </b-row>
        <b-row class="mb-5">
          <b-col>
            <b-input
              placeholder="Nhập ID đơn hàng"
              v-model="searchOrderId"
              append-icon="search"
              single-line
              hide-details
              class="btn-sm customer-input"
              size="sm"
              v-on:keyup.enter="onFilter()"
            ></b-input>
          </b-col>
          <b-col>
            <b-input
              placeholder="Nhập tên KH/Sdt"
              v-model="searchNameOrPhone"
              append-icon="search"
              single-line
              hide-details
              class="btn-sm customer-input"
              size="sm"
              v-on:keyup.enter="onFilter()"
            ></b-input>
          </b-col>
          <b-col>
            <multiselect
              v-model="value"
              :options="options"
              :multiple="true"
              track-by="code"
              :close-on-select="false"
              @select="onSelect($event)"
              @remove="onRemove($event)"
              :show-labels="false"
              :showNoResults="true"
              :showPointer="false"
              :custom-label="customLabel"
              placeholder="Chọn đặc điểm"
            >
              <template slot="tag">{{ '' }}</template>
              <template slot="selection" slot-scope="{ values, isOpen }"
                ><span
                  class="multiselect__single"
                  v-if="values.length &amp;&amp; !isOpen"
                  >{{ value.length }} đặc điểm đã chọn</span
                ></template
              >
              <span
                class="checkbox-label"
                slot="option"
                slot-scope="scope"
                @click.self="select(scope.option)"
              >
                {{ scope.option.name }}
                <input
                  class="test"
                  type="checkbox"
                  v-model="scope.option.checked"
                  @focus.prevent
                />
              </span>
              <span
                class="checkbox-label"
                style="font-size: 12px"
                slot="noResult"
              >
                Không có kết quả
              </span>
            </multiselect>
          </b-col>
          <b-col>
            <b-input
              placeholder="Mã coupon"
              v-model="searchCoupon"
              append-icon="search"
              single-line
              hide-details
              class="btn-sm customer-input"
              size="sm"
              v-on:keyup.enter="onFilter()"
            ></b-input>
          </b-col>
          <b-col>
            <b-input
              placeholder="Nhập mã IMEI"
              v-model="searchIMEI"
              append-icon="search"
              single-line
              hide-details
              class="btn-sm customer-input"
              size="sm"
              v-on:keyup.enter="onFilter()"
            ></b-input>
          </b-col>
        </b-row>
        <b-row class="mb-5">
          <b-col>
            <b-input
              placeholder="Nhập ID hóa đơn"
              v-model="searchId"
              append-icon="search"
              single-line
              hide-details
              class="btn-sm customer-input"
              size="sm"
              v-on:keyup.enter="onFilter()"
            ></b-input>
          </b-col>
          <b-col>
            <b-input
              placeholder="Nhập ghi chú"
              v-model="searchNote"
              append-icon="search"
              single-line
              hide-details
              class="btn-sm customer-input"
              size="sm"
              v-on:keyup.enter="onFilter()"
            ></b-input>
          </b-col>
          <b-col></b-col>
          <b-col></b-col>
          <b-col></b-col>
        </b-row>
        <b-row class="mb-5">
          <b-col md="1">
            <b-button
              style="fontweight: 600; width: 70px"
              variant="primary"
              size="sm"
              @click="onFilter"
              >Lọc</b-button
            >
          </b-col>
        </b-row>
        <table class="table b-table table-bordered-list table-hover col-md-12">
          <thead>
            <tr>
              <th class="text-center tdDate">Ngày</th>
              <th class="text-center tdCode">ID</th>
              <th class="text-center tdStore">Cửa hàng</th>
              <th class="text-center tdCustomer">Khách hàng</th>
              <th class="text-center tdProductName">Sản phẩm</th>
              <th class="text-center tdProductPrice">Giá</th>
              <th class="text-center tdQuantity">SL</th>
              <th class="text-center tdDiscountItem">C.Khấu</th>
              <th class="text-center tdTotalAmount">Tổng tiền</th>
              <th class="text-center tdTotalPayment">Thanh toán</th>
              <th class="text-center tdNote">Ghi chú</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="entry in bills"
              :key="entry.id"
              @mouseover="hoverBill"
              @mouseleave="outBill"
              :data="`row_${entry.billNumber}`"
            >
              <td
                v-if="entry.show"
                :rowspan="entry.groupSize"
                class="tdTextAlign tdDate"
              >
                <div class="text-center mt-2">
                  <span
                    title="Từ app mobile"
                    v-b-tooltip
                    v-if="entry.deviceType === 2"
                  >
                    <i
                      class="fas fa-mobile-alt text-dark"
                      style="font-size: 12px"
                    ></i>
                  </span>
                  <span title="Từ web" v-b-tooltip v-else>
                    <i
                      class="fas fa-globe-asia text-dark"
                      style="font-size: 12px"
                    ></i>
                  </span>
                </div>

                {{ entry.date }}
                <br />
                <p style="margin-bottom: 0px" v-if="entry.cashierName">
                  {{ entry.cashierName }}
                </p>
                <p
                  v-if="entry.saleName"
                  style="margin-bottom: 0px; font-weight: 600"
                >
                  NVBH:
                </p>
                {{ entry.saleName }}
              </td>
              <td class="tdCode" v-if="entry.show" :rowspan="entry.groupSize">
                <div class="orderCode" style="cursor: pointer; color: #3699ff">
                  <a
                    class="font-weight-bold"
                    v-bind:href="getLinkDoc(entry)"
                    target="_self"
                  >
                    {{ entry.billNumber }}
                  </a>
                </div>
                <div v-if="entry.type === 4" class="text-center">
                  <span class="text-success"
                    ><i
                      class="fas fa-shipping-fast"
                      title="Hóa đơn giao hàng từ đơn hàng"
                    ></i
                  ></span>
                  <div
                    class="orderCode"
                    @click="linkToOrder(entry.orderId)"
                    style="cursor: pointer"
                  >
                    <span
                      style="color: darkgoldenrod"
                      v-text="entry.orderId"
                    ></span>
                  </div>
                </div>
                <div
                  v-if="entry.type === 1 && entry.orderId"
                  class="text-center"
                >
                  <span class="text-warning"
                    ><i
                      class="fas fa-shopping-basket"
                      title="Hóa đơn từ đơn hàng"
                    ></i
                  ></span>
                  <div
                    class="orderCode"
                    @click="linkToOrder(entry.orderId)"
                    style="cursor: pointer"
                  >
                    <span
                      style="color: darkgoldenrod"
                      v-text="entry.orderId"
                    ></span>
                  </div>
                </div>
              </td>
              <td
                v-if="entry.show"
                :rowspan="entry.groupSize"
                class="tdTextAlign tdStore"
              >
                {{ entry.storeName }}
                <br />
                <span v-if="entry.companyName" style="font-size: 10px"
                  >( {{ entry.companyName }} )</span
                >
              </td>
              <td
                v-if="entry.show"
                :rowspan="entry.groupSize"
                class="tdTextAlign tdCustomer"
              >
                <p
                  class="orderCode"
                  @click="editUser(entry)"
                  style="margin-bottom: 0; cursor: pointer; font-weight: 550"
                >
                  {{ entry.customerName }}
                </p>
                <p
                  class="orderCode"
                  @click="editUser(entry)"
                  style="margin-bottom: 0; cursor: pointer"
                >
                  {{ entry.customerPhone }}
                </p>
              </td>
              <td class="tdTextAlign tdProductName">
                <div v-if="entry.productItem.typeProduct !== 3">
                  <div
                    v-if="entry.productItem.productType === 2"
                    class="font-size-gift"
                  >
                    <i
                      style="color: #2e7d32"
                      class="fas fa-gift ml-4 mr-1"
                      title="Quà tặng"
                    ></i>
                    {{ entry.productItem.productName }}
                  </div>
                  <div
                    v-else-if="entry.productItem.productType === 3"
                    class="font-size-attach"
                  >
                    <i
                      style="color: #37474f"
                      class="fas fa-tags ml-4 mr-1"
                      title="Bán kèm"
                    ></i>
                    {{ entry.productItem.productName }}
                  </div>
                  <div v-else>
                    {{ entry.productItem.productName }}
                  </div>
                  <p
                    class="text-imei-no"
                    v-if="entry.productItem.imeiCode.trim() !== ''"
                  >
                    IMEI: {{ entry.productItem.imeiCode }}
                  </p>
                </div>
                <div v-else>
                  <div
                    class="table-responsive"
                    v-show="
                      entry.productItem.comboItems !== undefined &&
                      entry.productItem.comboItems.length > 0
                    "
                  >
                    <table>
                      <tr>
                        <td style="border: none; padding-left: 0">
                          <p class="m-0">
                            {{ entry.productItem.productName }}
                          </p>
                        </td>
                      </tr>
                      <tr
                        v-for="(items, i) in entry.productItem.comboItems"
                        :key="i"
                        style="border-top: 1px solid gray"
                      >
                        <td style="border: none; width: -webkit-fill-available">
                          <i
                            style="color: rgb(24, 28, 50)"
                            class="fas fa-cube"
                          ></i>
                          {{ items.productName }}
                          <i style="font-size: unset; color: unset"
                            >({{ convertPrice(items.sellingPrice) }})</i
                          >
                        </td>
                        <td style="border: none; font-size: 13px">
                          {{ items.quantity }}
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </td>
              <td class="tdTextAlignPrice tdProductPrice">
                {{ convertPrice(entry.productItem.productPrice) }}
              </td>
              <td class="tdTextAlignPrice tdQuantity">
                {{ entry.productItem.quantity }}
              </td>
              <td class="tdTextAlignPrice tdDiscountItem">
                <p v-if="entry.productItem.discountType === 1">
                  {{ convertPrice(entry.productItem.discount) }}
                </p>
                <p
                  v-if="
                    entry.productItem.discountType === 2 &&
                    entry.productItem.discount > 0
                  "
                >
                  {{ convertPrice(entry.productItem.discount) }}%
                  <br />
                  <span style="color: #ffa800; font-style: italic">
                    {{
                      convertPrice(entry.productItem.discountItemAmount)
                    }}</span
                  >
                </p>
                <p
                  v-if="
                    entry.productItem.discountType === 2 &&
                    entry.productItem.discount === 0
                  "
                >
                  {{ convertPrice(entry.productItem.discount) }}
                </p>
              </td>
              <td
                class="tdTextAlignPrice tdTotalAmount"
                v-if="entry.show"
                :rowspan="entry.groupSize"
              >
                <p v-show="entry.shippingFee > 0" class="border-0">
                  <i
                    v-b-tooltip.hover
                    title="Phí ship"
                    class="fas fa-shipping-fast icon-color"
                  ></i>
                  : {{ convertPrice(entry.shippingFee) }}
                </p>
                <p
                  v-show="
                    entry.discountAmount > 0 ||
                    entry.discountAmount.includes('%') ||
                    entry.discountAmount.includes('.')
                  "
                  class="border-0 text-warning"
                >
                  <i
                    v-b-tooltip.hover
                    title="Chiết khấu tổng"
                    class="fa fa-arrow-down icon-color"
                  ></i>
                  : {{ entry.discountAmount }}
                </p>
                <b style="color: #3c763d">
                  {{ convertPrice(entry.totalAmount) }}</b
                >
              </td>
              <td
                class="tdTextAlignPrice tdTotalPayment"
                v-if="entry.show"
                :rowspan="entry.groupSize"
              >
                <p v-show="entry.pointToMoney > 0" class="border-0">
                  <i
                    v-b-tooltip.hover
                    title="Tiêu điểm"
                    class="fas fa-adjust icon-color"
                  ></i>
                  : {{ convertPrice(entry.pointToMoney) }}
                </p>
                <p v-show="entry.transferAmount > 0" class="border-0">
                  <i
                    v-b-tooltip.hover
                    :title="entry.titleTransfer"
                    class="fa fa-university icon-color"
                  ></i>
                  : {{ convertPrice(entry.transferAmount) }}
                </p>
                <p v-show="entry.creditAmount > 0" class="border-0">
                  <i
                    v-b-tooltip.hover
                    :title="entry.titleCredit"
                    class="fa fa-credit-card icon-color"
                  ></i>
                  : {{ convertPrice(entry.creditAmount) }}
                </p>
                <p v-show="entry.cashAmount > 0" class="border-0">
                  <i
                    v-b-tooltip.hover
                    :title="entry.titleCash"
                    class="fas fa-money-bill-alt icon-color"
                  ></i>
                  : {{ convertPrice(entry.cashAmount) }}
                </p>
                <p v-show="entry.installlAmount > 0" class="border-0">
                  <i
                    v-b-tooltip.hover
                    :title="entry.titleInstallment"
                    class="fa fa-cube icon-color"
                  ></i>
                  : {{ convertPrice(entry.installlAmount) }}
                </p>
                <!-- <span
                      style="font-weight: 700"
                      class="border-0 text-success"
                      >{{ convertPrice(entry.paymentAmount) }}</span
                    > -->
                <p v-if="entry.extraAmount > 0" class="d-block text-primary">
                  Tiền thừa:
                  <span class="d-block">{{
                    convertPrice(entry.extraAmount)
                  }}</span>
                </p>
                <p
                  v-if="entry.extraAmount < 0"
                  class="d-block"
                  style="color: #e65b5b"
                >
                  Còn thiếu:
                  <span class="d-block">
                    {{ convertPrice(entry.extraAmount * -1) }}</span
                  >
                </p>
                <p v-show="entry.leftAmount > 0">
                  Công nợ:
                  <span class="d-block">
                    {{ convertPrice(entry.leftAmount) }}</span
                  >
                </p>
              </td>
              <td
                class="tdTextAlign tdNote wrapword"
                v-if="entry.show"
                :rowspan="entry.groupSize"
                @mouseover="hoverPrice = true"
                @mouseleave="hoverPrice = false"
              >
                <p v-if="entry.note">{{ entry.note }}</p>
                <p v-if="entry.note && entry.warrantyNote">--------------</p>
                <p v-if="entry.warrantyNote">{{ entry.warrantyNote }}</p>
                <span @click="handleShowNoteModel(entry)">
                  <i v-if="hoverPrice" class="fas fa-edit"></i>
                </span>
              </td>
              <td
                v-if="entry.show"
                :rowspan="entry.groupSize"
                class="tdTextAlign"
              >
                <div class="d-flex justify-content-center">
                  <b-dropdown size="sm" id="dropdown-left" no-caret right>
                    <template slot="button-content">
                      <i
                        style="font-size: 1rem; padding-right: 0px"
                        class="flaticon2-settings"
                      ></i>
                    </template>
                    <b-dropdown-item @click="printBill(entry)">
                      <span style="color: #3f4254; font-size: 12px">
                        <i
                          style="font-size: 1rem"
                          class="flaticon2-printer"
                        ></i>
                        &nbsp; In hóa đơn
                      </span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="returnItem(entry)"
                      v-if="entry.isShowReturnBill"
                    >
                      <span style="color: #3f4254; font-size: 12px">
                        <i
                          style="font-size: 1rem"
                          class="flaticon2-reply-1"
                        ></i>
                        &nbsp; Trả hàng
                      </span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="rePostItem(entry)"
                    >
                      <span style="color: #3f4254; font-size: 12px">
                        <i style="font-size: 1rem" class="fas fa-redo"></i>
                        &nbsp; Tạo lại
                      </span>
                    </b-dropdown-item>
                    <b-dropdown-item @click="linkWarranty(entry)">
                      <span style="color: #3f4254; font-size: 12px">
                        <i style="font-size: 1rem" class="fas fa-award"></i>
                        &nbsp; Tạo phiếu bảo hành
                      </span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="editItem(entry)"
                      v-if="checkViewOnly() || entry.companyId !== 3"
                    >
                      <span style="color: #3f4254; font-size: 12px">
                        <i style="font-size: 1rem" class="flaticon-eye"></i>
                        &nbsp; Chi tiết hóa đơn
                      </span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="editItem(entry)"
                      v-if="!checkViewOnly() && entry.companyId === 3"
                    >
                      <span style="color: #3f4254; font-size: 12px">
                        <i style="font-size: 1rem" class="flaticon2-pen"></i>
                        &nbsp; Chỉnh sửa
                      </span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="showDeleteAlert(entry)"
                      v-if="
                        checkPermission('BILL_DELETE') && entry.companyId === 3
                      "
                    >
                      <span style="color: #3f4254; font-size: 12px">
                        <i
                          style="font-size: 1rem; color: #d33"
                          class="flaticon2-rubbish-bin-delete-button"
                        ></i>
                        &nbsp; Xóa
                      </span>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </td>
            </tr>
            <tr v-if="!onLoading">
              <td :colspan="4" class="tdTextAlignPrice text-weight">Tổng</td>
              <td></td>
              <td></td>
              <td class="tdTextAlignPrice text-weight">
                {{ totalQuantity }}
              </td>
              <td></td>
              <td class="tdTextAlignPrice text-weight">
                {{ convertPrice(totalAmount) }}
              </td>
              <td class="tdTextAlignPrice text-weight">
                {{ convertPrice(totalPayment) }}
              </td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </table>
        <vcl-table
          v-if="onLoading"
          :speed="5"
          :animate="true"
          :columns="11"
        ></vcl-table>

        <!-- End of Table session -->

        <template>
          <b-modal
            v-model="showEditPriceModal"
            hide-footer
            :title="'Sửa ghi chú cho hóa đơn ' + idBill"
          >
            <b-row class="mb-5">
              <b-col>
                <label class="labelInput" for="inputOriginalPrice"
                  >Ghi chú bán hàng</label
                >
                <b-form-textarea
                  v-model="note"
                  placeholder="Nhập ghi chú..."
                  rows="5"
                  max-rows="6"
                ></b-form-textarea>
              </b-col>
            </b-row>
            <b-row class="mb-5">
              <b-col>
                <label class="labelInput" for="inputOriginalPrice"
                  >Ghi chú bảo hành</label
                >
                <b-form-textarea
                  v-model="warrantyNote"
                  placeholder="Nhập ghi chú..."
                  rows="5"
                  max-rows="6"
                ></b-form-textarea>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-button
                  style="fontweight: 600; width: 70px"
                  variant="primary"
                  size="sm"
                  @click="updateNote"
                  >Lưu</b-button
                >
                <b-button
                  style="margin-left: 10px; font-weight: 600; width: 70px"
                  variant="secondary"
                  size="sm"
                  @click="handleShowNoteModel"
                  >Hủy</b-button
                >
              </b-col>
            </b-row>
          </b-modal>
        </template>

        <!-- Paginate session -->
        <b-row>
          <b-col>
            <p class="mt-3 text-dark" style="font-weight: 500">
              Tổng số hóa đơn:
              {{ convertPrice(totalItems) }}
            </p>
          </b-col>
          <b-col>
            <b-pagination-nav
              v-model="page"
              v-show="totalPages >= 2"
              class="customPagination"
              :link-gen="linkGen"
              :number-of-pages="totalPages"
              use-router
              @change="fetchListBill"
              align="right"
              first-class="page-item-first btn btn-icon btn-sm mr-1 my-1"
              prev-class="page-item-prev btn btn-icon btn-sm mr-.5 my-1"
              next-class="page-item-next btn btn-icon btn-sm mr-1 my-1 ml-.5"
              last-class="page-item-last btn btn-icon btn-sm my-1 "
              page-class="btn btn-icon btn-sm border-0 mr-.5 my-1"
            >
              <template v-slot:first-text>
                <span>
                  <i class="ki ki-bold-double-arrow-back icon-xs"></i>
                </span>
              </template>

              <template v-slot:prev-text>
                <i class="ki ki-bold-arrow-back icon-xs"></i>
              </template>

              <template v-slot:next-text>
                <i class="ki ki-bold-arrow-next icon-xs"></i>
              </template>

              <template v-slot:last-text>
                <span class="text-info">
                  <i class="ki ki-bold-double-arrow-next icon-xs"></i>
                </span>
              </template>
            </b-pagination-nav>
          </b-col>
        </b-row>
        <!-- End of Paginate session -->
      </template>
    </KTCodePreview>
    <template>
      <BillExcel :param="apiParams" :mode="mode" />
    </template>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
import Swal from 'sweetalert2';
import ApiService from '@/core/services/api.service';
import localData from '../../../utils/saveDataToLocal';
import { mapGetters } from 'vuex';
import Loader from '@/view/content/Loader.vue';
import decounce from 'debounce';
import { VclTable } from 'vue-content-loading';
// import { exportExcel } from './../../../utils/common';
// Import datePicker component
import datePicker from 'vue-bootstrap-datetimepicker';
// Import date picker css
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import jQuery from 'jquery';
// Using font-awesome 5 icons
import '@fortawesome/fontawesome-free/css/fontawesome.css';
import '@fortawesome/fontawesome-free/css/regular.css';
import '@fortawesome/fontawesome-free/css/solid.css';
import Multiselect from 'vue-multiselect';
import { VueAutosuggest } from 'vue-autosuggest';
import BillExcel from '../../components/bills/BillExcel.vue';
import moment from 'moment';
import { TIME_TRIGGER } from '../../../utils/constants';
jQuery.extend(true, jQuery.fn.datetimepicker.defaults, {
  icons: {
    time: 'far fa-clock',
    date: 'far fa-calendar',
    up: 'fas fa-arrow-up',
    down: 'fas fa-arrow-down',
    previous: 'fas fa-chevron-left',
    next: 'fas fa-chevron-right',
    today: 'fas fa-calendar-check',
    clear: 'far fa-trash-alt',
    close: 'far fa-times-circle',
  },
});

export default {
  data() {
    return {
      listStores: [{ value: '', text: 'Chọn cửa hàng' }],
      dpConfigs: {
        date: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
        start: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
        end: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
      },
      hoverPrice: false,
      dateFrom: moment().subtract(15, 'days').format('DD/MM/YYYY'),
      dateTo: moment().format('DD/MM/YYYY'),
      bills: [],
      onLoading: true,
      btnCreate: {
        fontWeight: '600!important',
      },
      isSearching: false,
      currentPage: 1,
      totalPages: 1,
      count: 1,
      totalItems: 0,
      page: 1,
      searchProductName: '',
      searchId: '',
      searchNameOrPhone: '',
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      idBill: '',
      showEditPriceModal: false,
      note: '',
      totalAmount: 0,
      totalPayment: 0,
      totalQuantity: 0,
      filteredOptions: [],
      optionsStore: [],
      selectedStore: null,
      searchStock: '',
      filteredOptionsEmployee: [],
      optionsEmployee: [
        {
          data: [],
        },
      ],
      selectedEmployee: null,
      searchEmployee: '',
      listStatus: [
        {
          id: 1,
          name: 'Hoạt động',
        },
        {
          id: 2,
          name: 'Đã khóa',
        },
      ],
      selectedStatus: 1,
      value: [],
      options: [
        {
          code: 1,
          name: 'Có tiền chiết khấu',
          checked: false,
        },
        {
          code: 2,
          name: 'Không có chiết khấu',
          checked: false,
        },
        {
          code: 3,
          name: 'Có tiền chuyển khoản',
          checked: false,
        },
        {
          code: 4,
          name: 'Có thanh toán quẹt thẻ',
          checked: false,
        },
        {
          code: 5,
          name: 'Có thanh toán trả góp',
          checked: false,
        },
        {
          code: 6,
          name: 'Có dùng tiền tích điểm',
          checked: false,
        },
        {
          code: 7,
          name: 'Có công nợ',
          checked: false,
        },
        {
          code: 8,
          name: 'Còn công nợ',
          checked: false,
        },
        {
          code: 9,
          name: 'Hết công nợ',
          checked: false,
        },
        {
          code: 10,
          name: 'Có tiền mặt',
          checked: false,
        },
        {
          code: 14,
          name: 'Còn thiếu',
          checked: false,
        },
        {
          code: 18,
          name: 'Còn thừa',
          checked: false,
        },
        {
          code: 15,
          name: 'Chưa thanh toán',
          checked: false,
        },
        {
          code: 11,
          name: 'Hóa đơn từ đơn hàng',
          checked: false,
        },
        {
          code: 13,
          name: 'Có cọc từ đơn hàng',
          checked: false,
        },
        {
          code: 12,
          name: 'Có quà tặng',
          checked: false,
        },
        {
          code: 17,
          name: 'Có sử dụng coupon',
          checked: false,
        },
        {
          code: 19,
          name: 'Có hoá đơn đỏ',
          checked: false,
        },
      ],
      selectedCompany: null,
      listCompany: [],
      valueStore: [],
      dpForm: {
        startDate: '',
        endDate: '',
      },
      warrantyNote: '',
      searchIMEI: '',
      searchOrderId: '',
      searchNote: '',
      searchCoupon: '',
      apiParams: {
        fromDate: null,
        toDate: null,
      },
      mode: null,
    };
  },
  components: {
    KTCodePreview,
    Loader,
    VclTable,
    datePicker,
    Multiselect,
    VueAutosuggest,
    BillExcel,
  },
  updated() {
    //this.getFillter();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Hóa đơn', route: 'bills' },
      { title: 'Danh sách hóa đơn' },
    ]);
    this.getFillter();
    this.page = this.$route.query.page;
    if (!this.page) {
      this.page = 1;
    }
  },
  created() {
    this.fetchStore();
    this.fetchStoreByUser();
    this.fetchCompany();
  },
  methods: {
    printBill: async function (item) {
      let routeData = this.$router.resolve({
        name: 'print-bill',
        query: { id: item.billNumber },
      });
      window.open(routeData.href, '_self');
    },
    fetchStore: async function () {
      this.optionsStore = [];
      ApiService.setHeader();
      ApiService.get(
        `/stores/getStores?companyId=${this.selectedCompany}`
      ).then((response) => {
        const stores = response.data.data;
        stores.map((element) => {
          let option = {
            code: element.id,
            name: element.shortName,
            checked: false,
          };
          this.optionsStore.push(option);
        });
      });
    },
    convertPrice: function (number = 0) {
      return new Intl.NumberFormat('vn-VN').format(number);
    },
    editItem: function (item) {
      const { href } = this.$router.resolve({
        name: 'update-bill',
        query: { id: item.billNumber },
      });
      window.open(href, '_self');
    },
    returnItem: function (item) {
      this.$router.push({
        name: 'add-return-bill',
        query: { id: item.billNumber },
      });
    },
    handleShowNoteModel(item) {
      this.showEditPriceModal = !this.showEditPriceModal;
      this.idBill = item.billNumber;
      this.note = item.note;
      this.warrantyNote = item.warrantyNote;
    },
    updateNote() {
      let data = {
        id: this.idBill,
        customerNote: this.note,
        warrantyNote: this.warrantyNote,
      };
      ApiService.setHeader();
      ApiService.put('bills/update-note', data)
        .then(({ data }) => {
          if (data.status === 1) {
            this.makeToastSuccess(data.message);
            this.showEditPriceModal = !this.showEditPriceModal;
            this.fetchListBill();
          } else {
            this.makeToastFaile(data.message);
          }
        })
        .catch(({ response }) => {
          this.makeToastFaile(response.$error);
        });
    },
    searchBill() {
      this.isSearching = true;
      this.fetchListBill();
    },
    fetchListBill: async function () {
      let listStoreId = [];
      this.optionsStore.forEach((element) => {
        if (element.checked) {
          listStoreId.push(element.code);
        }
      });
      this.page = this.$route.query.page;
      if (!this.page) {
        this.page = 1;
      }
      this.onLoading = true;
      let listCriteria = [];
      this.options.forEach((element) => {
        if (element.checked) {
          listCriteria.push(element.code);
        }
      });
      if (this.searchEmployee.trim() === '') {
        this.selectedEmployee = null;
      }
      let param = {
        page: this.page,
        limit: 10,
        id: this.searchId,
        customer: this.searchNameOrPhone,
        store: listStoreId,
        searchProduct: this.searchProductName,
        type: 1,
        fromDate: this.dateFrom
          ? moment(this.dateFrom, 'DD/MM/YYYY').format('yyyy-MM-DD')
          : null,
        toDate: this.dateTo
          ? moment(this.dateTo, 'DD/MM/YYYY HH:mm').format('yyyy-MM-DD 23:59')
          : null,
        saleId: this.selectedEmployee,
        listCriteria: listCriteria,
        companyId: this.selectedCompany,
        orderId: this.searchOrderId ? this.searchOrderId.trim() : '',
        searchIMEI: this.searchIMEI ? this.searchIMEI.trim() : '',
        searchNote: this.searchNote ? this.searchNote.trim() : '',
        searchCoupon: this.searchCoupon ? this.searchCoupon.trim() : '',
      };
      let paramQuery = {
        params: param,
      };
      ApiService.setHeader();
      ApiService.query('bills', paramQuery)
        .then(({ data }) => {
          this.bills = [];
          this.totalPages = data.data.totalPage;
          this.totalItems = data.data.totalRow;
          this.totalQuantity = 0;
          this.totalAmount = 0;
          this.totalPayment = 0;
          data.data.data.forEach((data) => {
            for (let index = 0; index < data.listProduct.length; index++) {
              const element = data.listProduct[index];
              let show = false;
              if (index === 0) {
                show = true;
              }
              let discountAmount = '';
              if (data.discountType === 2) {
                discountAmount = data.discountAmount + '%';
              } else if (data.discountType === 1) {
                discountAmount = this.convertPrice(data.discountAmount);
              }
              let item = {
                id: element.id,
                billNumber: data.billNumber,
                customerName: data.customerName,
                customerPhone: data.customerMobile,
                saleName: data.saleName,
                cashierName: data.cashierName,
                storeName: data.storeName,
                totalAmount: data.totalAmount,
                paymentAmount: data.paymentAmount,
                creditAmount: data.creditAmount,
                cashAmount: data.cashAmount,
                transferAmount: data.transferAmount,
                leftAmount: data.leftAmount,
                extraAmount: data.extraAmount,
                discountAmount: discountAmount,
                note: data.customerNote,
                warrantyNote: data.warrantyNote,
                productItem: element,
                date: data.createdAt,
                show: show,
                groupSize: data.listProduct.length,
                productType: element.productType,
                type: data.type,
                orderId: data.orderId,
                installlAmount: data.installlAmount,
                customerId: data.customerId,
                pointToMoney: data.pointToMoney,
                companyId: data.companyId,
                companyName: data.companyName,
                isShowReturnBill: data.canReturnBill,
                canEditBill: true,
                transferAccName: data.transferAccName,
                creditAccName: data.creditAccName,
                cashAccName: data.cashAccName,
                installmentAccName: data.installmentAccName,
                titleCash: data.cashAccName
                  ? 'Tiền mặt: ' + data.cashAccName
                  : 'Tiền mặt',
                titleTransfer: data.transferAccName
                  ? 'Chuyển khoản: ' + data.transferAccName
                  : 'Chuyển khoản',
                titleCredit: data.creditAccName
                  ? 'Quẹt thẻ: ' + data.creditAccName
                  : 'Quẹt thẻ',
                titleInstallment: data.installmentAccName
                  ? 'Trả góp: ' + data.installmentAccName
                  : 'Trả góp',
                shippingFee: data.shippingFee,
                deviceType: data.deviceType,
              };
              this.bills.push(item);
              this.totalQuantity += element.quantity;
              if (show) {
                this.totalAmount += data.totalAmount;
                this.totalPayment += data.paymentAmount;
              }
            }
          });
          this.onLoading = false;
        })
        .catch((err) => {
          this.onLoading = false;
          this.makeToastFaile(`Lỗi: ${err}`);
        });
    },
    deleteItem: async function (item) {
      ApiService.delete('/bills' + '/' + item.billNumber).then((data) => {
        const { status, message } = data.data;
        if (status === 1) {
          this.makeToastSuccess(message);
          this.fetchListBill();
        } else {
          this.makeToastFaile(message);
        }
      });
    },
    showDeleteAlert: function (item) {
      Swal.fire({
        title: 'Xóa hóa đơn!',
        text: 'Bạn có chắc muốn xóa hóa đơn này không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      }).then((result) => {
        if (result.value) {
          this.deleteItem(item);
        }
      });
    },
    checkPermission: function (condition) {
      return localData.checkPermission(condition);
    },
    checkViewOnly: function () {
      let count = 0;
      if (localData.checkPermission('BILL_VIEW')) {
        count++;
      }
      if (localData.checkPermission('BILL_UPDATE')) {
        count++;
      }
      if (count === 1) {
        return true;
      } else {
        return false;
      }
    },
    makeToastSuccess(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'success',
        solid: true,
      });
    },
    makeToastFaile(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'danger',
        solid: true,
      });
    },
    linkToOrder: function (orderId) {
      const { href } = this.$router.resolve({
        name: 'edit-order',
        params: { id: orderId },
      });
      window.open(href, '_blank');
    },
    debounceInput: decounce(() => {
      this.searchBill();
    }, TIME_TRIGGER),
    editUser: function (item) {
      const { href } = this.$router.resolve({
        name: 'update-customer',
        query: { id: item.customerId, phoneNo: item.customerPhone },
      });
      window.open(href, '_blank');
    },
    hoverBill: function (evt) {
      const items = jQuery(evt.currentTarget);
      const dataId = jQuery(items[0]).attr('data');
      const rows = jQuery(`[data=${dataId}]`);
      rows.addClass('tr-rowspan-hover');
    },
    outBill: function (evt) {
      const items = jQuery(evt.currentTarget);
      const dataId = jQuery(items[0]).attr('data');
      const rows = jQuery(`[data=${dataId}]`);
      rows.removeClass('tr-rowspan-hover');
    },
    fetchStoreByUser() {
      ApiService.setHeader();
      ApiService.get(`stores/get-by-user`).then(({ data }) => {
        if (data.status === 1) {
          this.optionsStore = [];
          const stores = data.data.stores;
          stores.forEach((element) => {
            let option = {
              code: element.id,
              name: element.shortName,
              checked: false,
            };
            this.optionsStore.push(option);
            if (!data.data.viewAll) {
              let index = this.optionsStore.findIndex(
                (item) => item.code == element.id
              );
              this.optionsStore[index].checked = true;
              this.valueStore.push(this.optionsStore[index]);
            }
          });
        }
        if (this.$route.query.listStore) {
          this.valueStore = [];
          if (Array.isArray(this.$route.query.listStore)) {
            this.$route.query.listStore.forEach((id) => {
              let index = this.optionsStore.findIndex(
                (item) => item.code == id
              );
              this.optionsStore[index].checked = true;
              this.valueStore.push(this.optionsStore[index]);
            });
          } else {
            let index = this.optionsStore.findIndex(
              (item) => item.code == this.$route.query.listStore
            );
            this.optionsStore[index].checked = true;
            this.valueStore.push(this.optionsStore[index]);
          }
        }
        this.fetchListBill();
        this.getListEmployee();
      });
    },
    onFilter() {
      this.$route.query.page = 1;
      this.changeURL();
      this.fetchListBill();
    },
    changeURL() {
      let queryObj = this.setFillter();
      this.$router.push({
        name: 'list-bill',
        query: queryObj,
      });
    },

    onSelectedEmployee(option) {
      this.selectedEmployee = option.item.id;
      this.searchEmployee = option.item.fullName;
    },
    onInputChangeEmployee(text) {
      if (!text) {
        text = '';
      }
      this.searchEmployee = text;
      this.debounceInputEmployee();
    },
    debounceInputEmployee: decounce(function () {
      this.getListEmployee();
    }, TIME_TRIGGER),
    getListEmployee() {
      this.optionsEmployee[0].data = [];
      this.filteredOptionsEmployee = [{ data: this.optionsEmployee[0].data }];
      let listStoreId = [];
      this.optionsStore.forEach((element) => {
        if (element.checked) {
          listStoreId.push(element.code);
        }
      });
      ApiService.setHeader();
      ApiService.get(
        `employees/get-by-store?employeeName=${this.searchEmployee}&storeId=${listStoreId}`
      ).then((data) => {
        const employees = data.data.data;
        employees.map((element) => {
          this.optionsEmployee[0].data.push(element);
        });
        this.filteredOptionsEmployee = [{ data: this.optionsEmployee[0].data }];
      });
    },
    onSelect(option) {
      let index = this.options.findIndex((item) => item.name == option.name);
      this.options[index].checked = true;
    },

    onRemove(option) {
      let index = this.options.findIndex((item) => item.name == option.name);
      this.options[index].checked = false;
    },
    customLabel(option) {
      return `${option.name}`;
    },
    onSelectStore(option) {
      let index = this.optionsStore.findIndex(
        (item) => item.name == option.name
      );
      this.optionsStore[index].checked = true;
    },

    onRemoveStore(option) {
      let index = this.optionsStore.findIndex(
        (item) => item.name == option.name
      );
      this.optionsStore[index].checked = false;
    },
    customLabelStore(option) {
      return `${option.name}`;
    },
    fetchCompany: async function () {
      this.listCompany = [];
      ApiService.setHeader();
      ApiService.get('/company').then((response) => {
        this.listCompany = response.data.data;
        this.listCompany.unshift({
          id: null,
          name: 'Tất cả',
        });
      });
    },
    onChangeCompany() {
      this.valueStore = [];
      this.searchStock = '';
      this.fetchStore();
    },
    getLinkDoc(item) {
      return `#/bills/update-bill?id=${item.billNumber}`;
    },
    countBill: async function () {
      let result = 0;
      let listStoreId = [];
      this.optionsStore.forEach((element) => {
        if (element.checked) {
          listStoreId.push(element.code);
        }
      });
      this.onLoading = true;
      let listCriteria = [];
      this.options.forEach((element) => {
        if (element.checked) {
          listCriteria.push(element.code);
        }
      });
      let param = {
        id: this.searchId,
        customer: this.searchNameOrPhone,
        store: listStoreId,
        searchProduct: this.searchProductName,
        type: 1,
        fromDate: this.dateFrom
          ? moment(this.dateFrom, 'DD/MM/YYYY').format('yyyy-MM-DD')
          : null,
        toDate: this.dateTo
          ? moment(this.dateTo, 'DD/MM/YYYY HH:mm').format('yyyy-MM-DD 23:59')
          : null,
        saleId: this.selectedEmployee,
        listCriteria: listCriteria,
        companyId: this.selectedCompany,
        orderId: this.searchOrderId ? this.searchOrderId.trim() : '',
        searchIMEI: this.searchIMEI ? this.searchIMEI.trim() : '',
        searchNote: this.searchNote ? this.searchNote.trim() : '',
      };
      let paramQuery = {
        params: param,
      };
      ApiService.setHeader();
      await ApiService.query('bills/count', paramQuery).then(({ data }) => {
        result = data.data;
      });
      return result;
    },
    getFillter() {
      this.searchId = '';
      if (this.$route.query.id) {
        this.searchId = this.$route.query.id;
      }
      this.searchProductName = '';
      if (this.$route.query.searchProduct) {
        this.searchProductName = this.$route.query.searchProduct;
      }
      this.searchNameOrPhone = '';
      if (this.$route.query.searchCustomer) {
        this.searchNameOrPhone = this.$route.query.searchCustomer;
      }
      if (this.$route.query.dateFrom) {
        this.dateFrom = this.$route.query.dateFrom;
      }
      if (this.$route.query.dateTo) {
        this.dateTo = this.$route.query.dateTo;
      }
      if (this.$route.query.companyId) {
        this.selectedCompany = this.$route.query.companyId;
      }
      this.value = [];
      if (this.$route.query.listCriteria) {
        if (Array.isArray(this.$route.query.listCriteria)) {
          this.$route.query.listCriteria.forEach((id) => {
            let index = this.options.findIndex((item) => item.code == id);
            this.options[index].checked = true;
            this.value.push(this.options[index]);
          });
        } else {
          let index = this.options.findIndex(
            (item) => item.code == this.$route.query.listCriteria
          );
          this.options[index].checked = true;
          this.value.push(this.options[index]);
        }
      }
      this.searchOrderId = '';
      if (this.$route.query.orderId) {
        this.searchOrderId = this.$route.query.orderId;
      }
      this.searchNote = '';
      if (this.$route.query.searchNote) {
        this.searchNote = this.$route.query.searchNote;
      }
      this.searchIMEI = '';
      if (this.$route.query.searchIMEI) {
        this.searchIMEI = this.$route.query.searchIMEI;
      }
      this.searchCoupon = '';
      if (this.$route.query.searchCoupon) {
        this.searchCoupon = this.$route.query.searchCoupon;
      }
    },
    setFillter() {
      let queryObj = {};
      if (this.searchId) {
        queryObj.id = this.searchId;
      }
      if (this.searchProductName) {
        queryObj.searchProduct = this.searchProductName;
      }
      if (this.searchNameOrPhone) {
        queryObj.searchCustomer = this.searchNameOrPhone;
      }
      if (this.dateFrom) {
        queryObj.dateFrom = this.dateFrom;
      }
      if (this.dateTo) {
        queryObj.dateTo = this.dateTo;
      }
      if (this.selectedCompany) {
        queryObj.companyId = this.selectedCompany;
      }
      let listCriteria = [];
      this.options.forEach((element) => {
        if (element.checked) {
          listCriteria.push(element.code);
        }
      });
      if (listCriteria.length > 0) {
        queryObj.listCriteria = listCriteria;
      }
      let listStoreId = [];
      this.optionsStore.forEach((element) => {
        if (element.checked) {
          listStoreId.push(element.code);
        }
      });
      if (listStoreId.length > 0) {
        queryObj.listStore = listStoreId;
      }
      if (this.searchOrderId && this.searchOrderId.trim() !== '') {
        queryObj.orderId = this.searchOrderId;
      }
      if (this.searchIMEI && this.searchIMEI.trim() !== '') {
        queryObj.searchIMEI = this.searchIMEI;
      }
      if (this.searchNote && this.searchNote.trim() !== '') {
        queryObj.searchNote = this.searchNote;
      }
      if (this.searchCoupon && this.searchCoupon.trim() !== '') {
        queryObj.searchCoupon = this.searchCoupon;
      }
      return queryObj;
    },
    linkWarranty(item) {
      this.$router.push({
        name: 'add-import-warranty',
        query: { billId: item.billNumber },
      });
    },
    showModalExcel() {
      this.mode = 1;
      this.apiParams = {
        fromDate: this.dateFrom,
        toDate: this.dateTo,
        productSearch: this.searchProductName,
        companyId: this.selectedCompany || 3,
        storeIds: this.optionsStore,
        criteriaIds: this.options,
        searchEmployee: this.selectedEmployee,
        orderId: this.searchOrderId,
        searchCustomer: this.searchNameOrPhone,
        couponCode: this.searchCoupon,
        searchIMEI: this.searchIMEI,
        billId: this.searchId,
        note: this.searchNote,
      };
      this.$bvModal.show('modal-bill-excel');
    },
    rePostItem: function (item) {
      this.$router.push({
        name: 'add-bill',
        query: { id: item.billNumber },
      });
    },
  },
  computed: {
    ...mapGetters(['breadcrumbs', 'pageTitle', 'layoutConfig']),
    loaderEnabled() {
      return !/false/.test(this.layoutConfig('loader.type'));
    },
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig('loader.logo');
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.orderCode:hover {
  text-decoration: underline;
}

.btn.btn-icon.btn-sm,
.btn-group-sm > .btn.btn-icon {
  height: calc(1.35em + 1.1rem + 2px);
  width: auto;
}
.customPagination /deep/ .page-item-first .page-link {
  background-color: #ecf1f6;
}

.customPagination /deep/ .page-item-prev .page-link {
  background-color: #ecf1f6;
}
.customPagination /deep/ .page-item-next .page-link {
  background-color: #ecf1f6;
}
.customPagination /deep/ .page-item-last .page-link {
  background-color: #ecf1f6;
}

.customPagination /deep/ .page-item .page-link {
  border-radius: 0.42rem;
  font-size: 0.9rem;
  line-height: 1rem;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  -webkit-box-align: center;
  height: 2.25rem;
  min-width: 2.25rem;
  padding: 0.5rem;
  color: #7e8299;
}

.customPagination /deep/ .page-item {
  color: #ecf1f6;
}

.customPagination /deep/ .page-item .router-link-exact-active {
  color: #fff;
}

.customPagination /deep/ .page-item .page-link:hover {
  background-color: #3699ff;
  color: #fff;
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, box-shadow 0.15s ease,
    -webkit-box-shadow 0.15s ease;
  opacity: 1;
}

.icon:hover {
  background-color: #90c6fc;
}

.sttClass {
  width: 20px;
}

.table th,
.table td {
  vertical-align: middle;
}
.tdCenter {
  vertical-align: left !important;
}
.tdTextAlign {
  vertical-align: middle;
}
.tdTextAlignPrice {
  text-align: right !important;
  vertical-align: middle;
}
.text-imei-no {
  font-size: 12px;
  font-style: italic;
  color: dodgerblue;
}

.table-bordered-list th {
  border: 0.5px solid #d2d4d6 !important;
  background-color: #e0e0e0;
}
.table-bordered-list td {
  border: 0.5px solid #d2d4d6;
}
.text-weight {
  font-weight: 550;
}
.font-size-gift {
  font-size: 11px;
}
.font-size-attach {
  font-size: 11px;
}

.tdDate {
  width: 5%;
  font-size: 11px;
}
.tdCode {
  width: 5%;
}
.tdStore {
  width: 5%;
}
.tdCustomer {
  width: 7%;
}
.tdProductName {
  width: 15%;
}
.tdProductPrice {
  width: 8%;
}
.tdQuantity {
  width: 3%;
}
.tdDiscountItem {
  width: 5%;
}
.tdTotalAmount {
  width: 8%;
}
.tdTotalPayment {
  width: 10%;
  font-weight: 700;
}
.tdNote {
  width: 19%;
}
.tdAction {
  width: 5%;
}
.wrapword {
  white-space: -moz-pre-wrap !important; /* Mozilla, since 1999 */
  white-space: -webkit-pre-wrap; /* Chrome & Safari */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  white-space: pre-wrap; /* CSS3 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
  word-break: break-all;
  white-space: normal;
}

.search-col {
  display: flex;
  align-items: center;
}
.first-search-col {
  display: flex;
  align-items: center;
}
.customer-input {
  border-style: solid;
  border-width: 1px;
  border-color: thistle;
  border-radius: 5px;
}
</style>
<style>
.autosuggest__results ul {
  list-style: none;
  cursor: pointer;
  margin-bottom: 0;
}

.autosuggest__results {
  position: absolute;
  background-color: #fff;
  z-index: 1;
  width: 100%;
  border: 1px solid #e0e0e0;
  overflow: scroll;
  max-height: 200px;
  position: absolute;
}

.autosuggest__results-container {
  position: relative;
  width: 100%;
}

#autosuggest__input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.375rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e5eaee;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: calc(1.35em + 1.1rem + 2px);
  padding: 0.55rem 0.75rem;
  font-size: 0.925rem;
  line-height: 1.35;
  border-radius: 0.28rem;
}

#autosuggest__input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

.autosuggest__results-item:hover {
  background-color: #ddd;
}

.autosuggest__results ul li {
  padding: 8px;
}
.test {
  position: absolute;
  right: 1vw;
}
.checkbox-label {
  display: block;
}
.multiselect {
  min-height: initial;
}
.multiselect__tags {
  display: block;
  padding: 3px 40px 0 8px;
  border-radius: 5px;
  border: 1px solid #ced4da !important;
  background: #fff;
  font-size: 12px;
  min-height: initial;
}
.multiselect__element {
  min-height: initial;
  display: block;
  background: #fff;
  font-size: 12px;
}
.multiselect__input {
  min-height: initial;
  display: block;
  background: #fff;
  font-size: 12px;
}
.multiselect__single {
  font-size: 12px;
}
</style>
